#primary-nav .level-1 {
	width: 100 / 7 * 1%;
	padding: 8px 3px;

	a {
		font-size: 15px;
	}
}

.block-menu-block .content {
	-webkit-column-count: 3;
	-moz-column-count: 3;
	column-count: 3;
	-webkit-column-gap: 15px;
	-moz-column-gap: 15px;
	column-gap: 15px;
}

.brand__lockup img {
	height: 84px;
}

.facultyp {
	.result {
		width: 100%;
		float: left;
		margin: 10px 0;
		padding: 20px 10px 0 10px;
		border-top: 1px solid #ddd;

		.physician_pic {
			width: 15%;
			float: left;

			a img {
				float: left;
				margin-top: 0;
			}
		}

		.col_1 {
			width: 30%;
			float: left;
			padding: 0 5px;

			h3 {
				margin-top: 0;
			}

			p {
				margin-bottom: 5px;
			}
		}

		.col_2 {
			width: 23%;
			float: left;
			padding: 0 5px;

			h3 {
				margin-top: 0;
			}

			ul {
				padding-left: 0;
			}

			li {
				list-style: none;
			}

			p {
				margin-bottom: 0;
			}
		}

		.col_3 {
			width: 32%;
			float: left;
			padding: 0 5px;

			li {
				list-style: none;
			}

			ul {
				padding-left: 0;
			}
		}
	}
}


.pane-gallery-import-panel-pane-1 {
	.views-field-field-image-url {
		height: 120px;
		overflow: hidden;
		margin-top: 10px;
	}

	.views-field-created {
		font-size: 11px;
		position: relative;
		top: -18px;
		background-color: #fff;
		opacity: 0.5;
		padding-left: 4px;
	}

	.views-field-field-image-description {
		top: -18px;
		position: relative;
	}
}


.galslide {
	max-height: 350px;
	overflow: hidden;
	background-color: #fff;

	.field-content {

		img {
			margin: 0 auto;
			width: 550px;
			height: auto;
		}
	}
}

.cycle-pager {
	display: block;
	width: 100%;
	z-index: 500;
	position: absolute;
	top: 275px;
	overflow: hidden;
	text-align: center;

	span {
		font-family: arial;
		font-size: 50px;
		width: 16px;
		height: 16px;
		display: inline-block;
		color: #ddd;
		cursor: pointer;
		margin: 0 2px;
	}

	span.cycle-pager-active {
		color: #e87722;
	}
}

#drawer-nav .level-2 {
	@include breakpoint($md) {
		padding-right: 5px;
	}
}

span.tagline {
	font-size: 20px;
	line-height: 25px;
	text-align: center;
	margin: 40px 0;
	width: 100%;
	display: block;
}

.radix-whelan {
	.btn {
		height: 30px;
		line-height: 18px;
		padding: 6px 20px;
		margin-top: 20px;
		border-radius: 4px;
		font-size: 13px;
	}

	.ctabox {
		border: 1px solid #ccc;
		padding: 5px;

		p {
			font-size: 13px;
		}
	}
}

.views-field-created {
	.field-content {
		font-size: 13px;
		color: #777;
	}
}


.cycle-prev,
.cycle-next {
	background-size: 65%;

	&:hover {
		opacity: 0.9;
	}
}

.pane-gallery-import-panel-pane-2 {
	margin: 20px 0;
}

.cycle-prev {
	background-position: 35% 55%;
}

.cycle-next {
	background-position: 65% 55%;
}

.pane-homepage-callouts-panel-pane-5 {
	h2 {
		margin-top: 40px;
	}
}

.pane-gallery-import-panel-pane-3 {
	.views-field-created {
		font-size: 11px;
		position: relative;
		top: -22px;
		background-color: #fff;
		opacity: 0.5;
		padding-left: 4px;

		span.field-content {
			font-size: 11px;
			color: #000;
		}
	}

	.views-field-field-image-description {
		font-size: 13px;
		position: relative;
		top: -22px;
	}
}

.pane-wcmc-second-level-nav {
	width: 100%;
	float: left;
}

.main-content table {
	width: 100%;
	margin: 10px 0;

	td {
		padding: 5px;
	}
}

.view-homepage-callouts {
	.views-field-field-featured-image {
		img {
			margin: 20px 0;
		}
	}
}

#panels-ipe-paneid-53 {
	border: 1px solid #ccc;
	padding: 0 10px;
	margin-top: 20px;

	h3 {
		margin-top: 10px;
	}

	p {
		font-size: 13px;
	}
}
