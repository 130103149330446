/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
#primary-nav .level-1 {
  width: 14.28571%;
  padding: 8px 3px; }
  #primary-nav .level-1 a {
    font-size: 15px; }

.block-menu-block .content {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px; }

.brand__lockup img {
  height: 84px; }

.facultyp .result {
  width: 100%;
  float: left;
  margin: 10px 0;
  padding: 20px 10px 0 10px;
  border-top: 1px solid #ddd; }
  .facultyp .result .physician_pic {
    width: 15%;
    float: left; }
    .facultyp .result .physician_pic a img {
      float: left;
      margin-top: 0; }
  .facultyp .result .col_1 {
    width: 30%;
    float: left;
    padding: 0 5px; }
    .facultyp .result .col_1 h3 {
      margin-top: 0; }
    .facultyp .result .col_1 p {
      margin-bottom: 5px; }
  .facultyp .result .col_2 {
    width: 23%;
    float: left;
    padding: 0 5px; }
    .facultyp .result .col_2 h3 {
      margin-top: 0; }
    .facultyp .result .col_2 ul {
      padding-left: 0; }
    .facultyp .result .col_2 li {
      list-style: none; }
    .facultyp .result .col_2 p {
      margin-bottom: 0; }
  .facultyp .result .col_3 {
    width: 32%;
    float: left;
    padding: 0 5px; }
    .facultyp .result .col_3 li {
      list-style: none; }
    .facultyp .result .col_3 ul {
      padding-left: 0; }

.pane-gallery-import-panel-pane-1 .views-field-field-image-url {
  height: 120px;
  overflow: hidden;
  margin-top: 10px; }

.pane-gallery-import-panel-pane-1 .views-field-created {
  font-size: 11px;
  position: relative;
  top: -18px;
  background-color: #fff;
  opacity: 0.5;
  padding-left: 4px; }

.pane-gallery-import-panel-pane-1 .views-field-field-image-description {
  top: -18px;
  position: relative; }

.galslide {
  max-height: 350px;
  overflow: hidden;
  background-color: #fff; }
  .galslide .field-content img {
    margin: 0 auto;
    width: 550px;
    height: auto; }

.cycle-pager {
  display: block;
  width: 100%;
  z-index: 500;
  position: absolute;
  top: 275px;
  overflow: hidden;
  text-align: center; }
  .cycle-pager span {
    font-family: arial;
    font-size: 50px;
    width: 16px;
    height: 16px;
    display: inline-block;
    color: #ddd;
    cursor: pointer;
    margin: 0 2px; }
  .cycle-pager span.cycle-pager-active {
    color: #e87722; }

@media screen and (min-width: 992px) {
  #drawer-nav .level-2 {
    padding-right: 5px; } }

span.tagline {
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  margin: 40px 0;
  width: 100%;
  display: block; }

.radix-whelan .btn {
  height: 30px;
  line-height: 18px;
  padding: 6px 20px;
  margin-top: 20px;
  border-radius: 4px;
  font-size: 13px; }

.radix-whelan .ctabox {
  border: 1px solid #ccc;
  padding: 5px; }
  .radix-whelan .ctabox p {
    font-size: 13px; }

.views-field-created .field-content {
  font-size: 13px;
  color: #777; }

.cycle-prev,
.cycle-next {
  background-size: 65%; }
  .cycle-prev:hover,
  .cycle-next:hover {
    opacity: 0.9; }

.pane-gallery-import-panel-pane-2 {
  margin: 20px 0; }

.cycle-prev {
  background-position: 35% 55%; }

.cycle-next {
  background-position: 65% 55%; }

.pane-homepage-callouts-panel-pane-5 h2 {
  margin-top: 40px; }

.pane-gallery-import-panel-pane-3 .views-field-created {
  font-size: 11px;
  position: relative;
  top: -22px;
  background-color: #fff;
  opacity: 0.5;
  padding-left: 4px; }
  .pane-gallery-import-panel-pane-3 .views-field-created span.field-content {
    font-size: 11px;
    color: #000; }

.pane-gallery-import-panel-pane-3 .views-field-field-image-description {
  font-size: 13px;
  position: relative;
  top: -22px; }

.pane-wcmc-second-level-nav {
  width: 100%;
  float: left; }

.main-content table {
  width: 100%;
  margin: 10px 0; }
  .main-content table td {
    padding: 5px; }

.view-homepage-callouts .views-field-field-featured-image img {
  margin: 20px 0; }

#panels-ipe-paneid-53 {
  border: 1px solid #ccc;
  padding: 0 10px;
  margin-top: 20px; }
  #panels-ipe-paneid-53 h3 {
    margin-top: 10px; }
  #panels-ipe-paneid-53 p {
    font-size: 13px; }
